import * as React from "react"
import { CSSProperties } from "react"
import CSS from "csstype"

import classNames from "../../../utils/classNames"
import { getSizeClass, getSizeValue, SizeProperty } from "../../utils"


export interface BoxProps {
    flex?: CSS.FlexProperty<number>
    grow?: number
    shrink?: number
    column?: boolean
    row?: boolean
    direction?: CSS.FlexDirectionProperty
    wrap?: boolean
    alignContent?: CSS.AlignContentProperty
    justifyContent?: CSS.JustifyContentProperty
    alignItems?: CSS.AlignItemsProperty
    alignSelf?: CSS.AlignSelfProperty
    width?: CSS.WidthProperty<number>
    height?: CSS.HeightProperty<number>
    maxWidth?: CSS.WidthProperty<number>
    maxHeight?: CSS.HeightProperty<number>
    minWidth?: CSS.WidthProperty<number>
    minHeight?: CSS.HeightProperty<number>
    overflowX?: CSS.OverflowXProperty
    overflowY?: CSS.OverflowXProperty
    style?: CSSProperties
    className?: string
    padded?: boolean | SizeProperty
    fill?: boolean
    centerContent?: boolean
    element?: React.ElementType
    onClick?: () => void
    children?: any
    innerRef?: any
}

class Box extends React.Component<BoxProps> {

    render() {

        let padding: string | number | undefined = undefined
        if (this.props.padded === true) {
            padding = 20
        } else if (this.props.padded) {
            padding = getSizeValue(this.props.padded)
        }

        const flexDirection = this.props.direction
            || (this.props.column ? "column" : undefined)
            || (this.props.row ? "row" : undefined)
            || "column"

        const style: CSSProperties = {
            display: "flex",
            flexDirection: flexDirection,
            flex: this.props.flex,
            flexGrow: this.props.grow || this.props.fill ? 1 : undefined,
            flexShrink: this.props.shrink,
            flexWrap: this.props.wrap ? "wrap" : undefined,
            alignContent: this.props.alignContent,
            justifyContent: this.props.justifyContent || (this.props.centerContent ? "center" : undefined),
            alignItems: this.props.alignItems,
            alignSelf: this.props.alignSelf,
            width: this.props.width,
            height: this.props.height,
            maxWidth: this.props.maxWidth,
            maxHeight: this.props.maxHeight,
            minWidth: this.props.minWidth,
            minHeight: this.props.minHeight,
            overflowX: this.props.overflowX,
            overflowY: this.props.overflowY,
            padding: padding,
            position: "relative",
            ...this.props.style
        }

        const sizeClass = getSizeClass(padding)

        const className = classNames(this.props.className, "mx-box", sizeClass)

        return React.createElement(
            this.props.element ?? "div",
            {
                className,
                style,
                onClick: this.props.onClick,
                ref: this.props.innerRef
            },
            this.props.children
        )
    }
}

export default React.forwardRef<HTMLElement, BoxProps>((props, ref) => <Box {...props} innerRef={ref} />)
