import * as React from "react"
import { useEffect, useState } from "react"


export function OnDevice(props: {
    mobile?: boolean
    tablet?: boolean
    desktop?: boolean
    render: () => any
}) {
    const {
        mobile = false,
        tablet = false,
        desktop = false,
        render
    } = props

    const windowWidth = useWindowWidth()
    const [key, setKey] = useState("mobile")

    useEffect(() => {
        // When React does the initial re-hydrate, it will assume that the first render is the same as in the SSR.
        // This results in children of OnDevice to only partially re-render.
        // We use this useEffect + key to force a complete re-render.

        if (desktop && windowWidth >= 960) {
            setKey("desktop")
        } else if (tablet && windowWidth >= 768 && windowWidth < 960) {
            setKey("tablet")
        } else if (mobile && windowWidth < 768) {
            setKey("mobile")
        } else {
            setKey("mobile")
        }
    }, [windowWidth])


    if (desktop && windowWidth >= 960) {
        return doRender(key, render)
    } else if (tablet && windowWidth >= 768 && windowWidth < 960) {
        return doRender(key, render)
    } else if (mobile && windowWidth < 768) {
        return doRender(key, render)
    } else {
        return null
    }
}

function doRender(key: string, render: () => any) {
    return (
        <React.Fragment key={key}>
            {render()}
        </React.Fragment>
    )
}

function useWindowWidth() {
    const window = getWindow()
    const innerWidth = window ? window.innerWidth : 500

    const [width, setWidth] = useState(innerWidth)
    const onWindowResize = () => setWidth(window!.innerWidth)

    useEffect(() => {
        window!.addEventListener("resize", onWindowResize)
        return () => window!.removeEventListener("resize", onWindowResize)
    })

    return width
}

function getWindow(): Window | undefined {
    return typeof window !== "undefined" ? window : undefined
}
