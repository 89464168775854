import * as React from "react"

import classNames from "@molnx-sites/common/utils/classNames"
import { PageSection } from "src/components"

import styles from "./PageNavigation.module.scss"

export function PageNavigation(props: {
    className?: string
    children?: any
}) {
    const className = classNames(
        styles.PageNavigation,
        props.className
    )

    return (
        <PageSection className={className}>
            {props.children}
        </PageSection>
    )
}