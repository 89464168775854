import * as React from "react"

import classNames from "../../utils/classNames"

import BigLogo from "./logo-big.svg"
import SmallLogo from "./logo-small.svg"
import TinyLogo from "./logo-tiny.svg"

import styles from  "./Logo.module.scss"


export function Logo(props: {
    tiny?: boolean
    small?: boolean
    big?: boolean
    huge?: boolean
    dark?: boolean
    light?: boolean
    style?: React.CSSProperties
}) {
    const className = classNames(
        styles.Logo,
        props.tiny ? styles.Tiny : undefined,
        props.small ? styles.Small : undefined,
        props.big ? styles.Big : undefined,
        props.huge ? styles.Huge : undefined,
        props.dark ? styles.Dark : undefined,
        props.light ? styles.Light : undefined,
    )

    if (props.tiny) {
        return <TinyLogo className={className} style={props.style} />
    } else if (props.small) {
        return <SmallLogo className={className} style={props.style} />
    } else if (props.big) {
        return <BigLogo className={className} style={props.style} />
    } else {
        return <SmallLogo className={className} style={props.style} />
    }
}