// TODO better name than utils

export type SizeProperty = string | number | "small" | "medium" | "large" | "x-large"

const sizeValues = {
    "x-small": 5,
    "small": 10,
    "medium": 20,
    "large": 40,
    "x-large": 80
}

export function getSizeValue(size: SizeProperty): string | number
export function getSizeValue(size?: SizeProperty): string | number | undefined
export function getSizeValue(size?: SizeProperty): string | number | undefined {
    if (size === undefined) return undefined
    return (sizeValues as any)[size] ?? size as string | number
}

const sizeClasses = {
    5: "mx-x-small",
    10: "mx-small",
    20: "mx-medium",
    40: "mx-large",
    80: "mx-x-large"
}

export function getSizeClass(size: SizeProperty): string
export function getSizeClass(size?: SizeProperty): string | undefined
export function getSizeClass(size?: SizeProperty): string | undefined {
    if (size === undefined) return undefined
    const sizeValue = getSizeValue(size)
    return (sizeClasses as any)[sizeValue] ?? ""
}
