import * as React from "react"

import classNames from "@molnx-sites/common/utils/classNames"

import { Logo } from "@molnx-sites/common/components"
import { PageSection } from "src/components"

import styles from "./PageHeader.module.scss"

export function PageHeader(props: {
    header: any
    large?: boolean
    className?: string
    children?: any
}) {
    const className = classNames(
        styles.PageHeader,
        props.className,
        props.large ? "large" : undefined
    )

    return (
        <PageSection dark className={className}>
            <Logo small light/>
            <h1>{props.header}</h1>

            {props.children}
        </PageSection>
    )
}