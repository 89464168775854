import React, { useCallback, useEffect, useState } from "react"
import { Link } from "gatsby"

import { HBox, Spacer, VBox, Logo, Icon } from "@molnx-sites/common/components"
import classNames from "@molnx-sites/common/utils/classNames"
import { Portal, PageColumn } from "src/components"

import styles from "./Header.module.scss"

export default function MobileNav(props: {}) {
    const [showMobileNav, setShowMobileNav] = useState(false)

    const onHamburgerClick = useCallback(() => {
        setShowMobileNav(!showMobileNav)
    }, [setShowMobileNav, showMobileNav])

    const onModalHide = useCallback(() => {
        setShowMobileNav(false)
    }, [setShowMobileNav])

    return (
        <HBox className={styles.Content}>
            <Link to="/">
                <Logo tiny/>
            </Link>
            <Spacer/>

            <div className={styles.Hamburger} onClick={onHamburgerClick}><Icon solid="bars" /></div>
            <MobileNavModal show={showMobileNav} onHide={onModalHide}/>
        </HBox>
    )
}


export function MobileNavModal(props: {
    show: boolean
    onHide: () => void
}) {
    const {
        show,
        onHide
    } = props

    const [className, setClassName] = useState(styles.MobileNav)

    useEffect(() => {
        setTimeout(() => {
            setClassName(classNames(
                styles.MobileNav,
                show ? "isOpen" : undefined
            ))
        }, 0)
    }, [show, setClassName])

    return (
        <Portal show={show} onHide={onHide} className={className}>
            <PageColumn>
                <div className={styles.Sections}>
                    <Section header="Services">
                        <ul>
                            <li><Link to="/dispatch">Dispatch</Link></li>
                        </ul>
                    </Section>

                    <Section header="Help">
                        <ul>
                            <li><a href="https://molnx.com/docs/">Documentation</a></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                            <li><Link to="/support">Support</Link></li>
                        </ul>
                    </Section>

                    <Section header="Company">
                        <ul>
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/news">News</Link></li>
                            <li><Link to="/blog">Blog</Link></li>
                        </ul>
                    </Section>
                </div>
            </PageColumn>
        </Portal>
    )
}

function Section(props: {
    header: string
    children?: any
}) {
    const {
        header,
        children
    } = props

    return (
        <VBox className={styles.Section}>
            <strong>{header}</strong>
            <hr/>
            {children}
        </VBox>
    )
}