import * as React from "react"
import * as CSS from "csstype"

import classNames from "../../../utils/classNames"


function GridItem(props: {
    width: number
    area?: string
    children: any
}) {
    return (
        <div
            style={{
                gridArea: props.area
            }}
        >
            {props.children}
        </div>
    )
}

interface GridProps {
    templateColumns?: string | string[]
    templateRows?: string | string[]
    templateAreas?: string
    autoRows?: CSS.GridAutoRowsProperty<number>
    autoColumns?: CSS.GridAutoColumnsProperty<number>
    autoFlow?: CSS.GridAutoFlowProperty
    gap?: CSS.GapProperty<number>
    columnGap?: CSS.GapProperty<number>
    rowGap?: CSS.GapProperty<number>
    fill?: boolean
    padded?: boolean
    children?: any
    className?: string
    style?: React.CSSProperties
}

const Grid: { (props: GridProps): any, Item: (props: any) => any } = (props: GridProps) => {
    let templateColumns = undefined
    if (Array.isArray(props.templateColumns)) {
        templateColumns = props.templateColumns.join(" ")
    } else if (props.templateColumns) {
        templateColumns = props.templateColumns
    }

    let templateRows = undefined
    if (Array.isArray(props.templateRows)) {
        templateRows = props.templateRows.join(" ")
    } else if (props.templateColumns) {
        templateRows = props.templateRows
    }

    const className = classNames("Grid", props.className)

    return (
        <div className={className} style={{
            display: "grid",
            gridTemplateAreas: props.templateAreas,
            padding: props.padded ? 20 : undefined,
            gridTemplateColumns: templateColumns,
            gridTemplateRows: templateRows,
            gridAutoRows: props.autoRows,
            gridAutoColumns: props.autoColumns,
            gridAutoFlow: props.autoFlow,
            gap: props.gap,
            columnGap: props.columnGap,
            rowGap: props.rowGap,
            flex: props.fill ? 1 : undefined,
            ...props.style
        }}>
            {props.children}
        </div>
    )
}

Grid.Item = GridItem


export function gridArea(area: string): { style: Partial<React.CSSProperties> } {
    return { style: { gridArea: area } }
}

export default Grid