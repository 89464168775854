import * as React from "react"

import classNames from "../../utils/classNames"

import styles from "./Code.module.scss"

export function Code(props: {
    description?: any
    children?: any
}) {
    const children = typeof props.children === "string" ? (
        makeLines(props.children)
    ) : props.children

    return (
        <div className={classNames(styles.Code, "Code")}>
            <div className={styles.CodeContent}>
                {children}
            </div>
            <div className={styles.CodeDescription}>
                {props.description}
            </div>
        </div>
    )
}

export function Line(props: {
    highlight?: boolean
    children?: any
}) {
    const className = classNames(
        styles.Line,
        props.highlight ? styles.Highlight : undefined
    )

    return (
        <div className={className}>
            {props.children}
        </div>
    )
}

export function Var(props: {
    description?: any
    children?: any
}) {
    return (
        <span className={styles.Var}>
            {props.children}
            <div className={styles.VarDescription}>{props.description}</div>
        </span>
    )
}

function makeLines(text: string) {
    return text
        .trimEnd()
        .split("\n")
        .map((it, i) => makeLine(it, i))
}

function makeLine(text: string, number: number) {
    let rawLine = text
    let highlight = false
    if (rawLine.endsWith("|h")) {
        highlight = true
        rawLine = rawLine.substr(0, rawLine.length-2).trimEnd()
    }

    return (<Line key={number} highlight={highlight}>{injectVars(rawLine)}</Line>)
}

function injectVars(line: string): any[] {
    // Inject <Var>-elements when encountering "{{text|description}}" patterns in the line

    const matches = Array.from(matchAll(line, /{{([^{}]*)\|([^{}]*)}}/g))
    if (matches.length === 0) {
        return [line]
    }

    const result: any[] = []
    let lastVarEnd = 0

    matches.forEach(it => {
        const varStart = it.index!
        const varEnd = varStart + it[0].length
        result.push(line.substring(lastVarEnd, varStart))

        const varElement = <Var key={varStart} description={it[2]}>{it[1]}</Var>
        result.push(varElement)

        lastVarEnd = varEnd
    })

    result.push(line.substring(lastVarEnd, line.length))

    return result
}

function matchAll(str: string, regexp: RegExp): RegExpMatchArray[] {
    const result: RegExpMatchArray[] = []

    let match: RegExpExecArray | null
    while ((match = regexp.exec(str)) !== null) {
        result.push(match)
    }

    return result
}