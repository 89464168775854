import * as React from "react"

import classNames from "@molnx-sites/common/utils/classNames"
import { Spacer, VBox } from "@molnx-sites/common/components"
import { Header, Footer } from "src/components"

import styles from "./Page.module.scss"


export function Page(props: {
    className?: string
    children: any
}) {
    return (
        <VBox className={classNames(styles.Root, props.className)}>
            <Header/>
            <VBox fill>
                {props.children}
            </VBox>
            <Footer/>
        </VBox>
    )
}
