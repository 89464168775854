import * as React from "react"
import { CSSProperties } from "react"

import { getSizeValue, SizeProperty } from "../../utils"

import Box from "../Box"

interface SpacerProps {
    width?: SizeProperty
    height?: SizeProperty
    inline?: boolean
}

export default class Spacer extends React.Component<SpacerProps> {
    render() {
        const style: CSSProperties = {
            width: getSizeValue(this.props.width),
            height: getSizeValue(this.props.height),
            flexGrow: !(this.props.width || this.props.height) ? 1 : undefined,
            display: this.props.inline ? "inline-block" : undefined
        }

        const element = this.props.inline ? "span" : undefined

        return <Box style={style} element={element} />
    }
}
