import * as React from "react"
import { useCallback, useEffect } from "react"
import * as ReactDOM from "react-dom"
import classNames from "@molnx-sites/common/utils/classNames"

import styles from "./Portal.module.scss"

let portalDiv: HTMLElement | undefined = undefined

function getPortalDiv() {
    if (!portalDiv) {
        portalDiv = document.getElementById("portal")!
    }

    return portalDiv
}

export function Portal(props: {
    show: boolean
    onHide?: () => void
    className?: string
    children?: any
}) {
    const document = getDocument()
    if (!document) {
        return null
    }

    const className = classNames(
        styles.Portal,
        props.show ? styles.Shown : styles.Hidden,
        props.className
    )

    const {
        show,
        onHide
    } = props

    const onClick = useCallback((e: React.MouseEvent) => {
        const className = (e.target as HTMLElement).className ?? ""
        if (className.includes("close") || e.target instanceof HTMLAnchorElement) {
            onHide?.()
        }

        e.preventDefault()
    }, [onHide])

    const makeOnOutsideClick = (show: boolean, onHide?: () => void) => (e: Event) => {
        if (show && !e.defaultPrevented) {
            onHide?.()
        }
    }

    useEffect(() => {
        const listener = makeOnOutsideClick(show, onHide)
        document.addEventListener("click", listener)
        return () => document.removeEventListener("click", listener)
    }, [show, onHide])

    const container = (
        <div
            className={className}
            onClick={onClick}
        >
            {props.children}
        </div>
    )

    return ReactDOM.createPortal(container, getPortalDiv())
}


function getDocument(): Document | undefined {
    return typeof document !== "undefined" ? document : undefined
}