import * as React from "react"

import classNames from "../../utils/classNames"

import styles from "./Icon.module.scss"

export function Icon(props: {
    brand?: string
    solid?: string
    regular?: string
    light?: string
    duotone?: string
    size?: "xs" | "sm" | "lg" | "2x" | "3x" | "5x" | "7x" | "10x"
    style?: React.CSSProperties
}) {
    const className = classNames(
        styles.Icon,
        "Icon",
        props.brand ? `fab fa-${props.brand}` : undefined,
        props.solid ? `fas fa-${props.solid}` : undefined,
        props.regular ? `far fa-${props.regular}` : undefined,
        props.light ? `fal fa-${props.light}` : undefined,
        props.duotone ? `fad fa-${props.duotone}` : undefined,
        props.size ? `fa-${props.size}` : undefined
    )

    return <i className={className} style={props.style} />
}