import * as React from "react"
import classNames from "../../utils/classNames"

import styles from "./BigButton.module.scss"

export function BigButton(props: {
    href?: string,
    children?: any,
    style?: React.CSSProperties
    className?: string
}) {
    return <a
        href={props.href}
        style={props.style}
        className={classNames(props.className, styles.BigButton)}
    >
        {props.children}
    </a>
}