import { Link } from "gatsby"
import React from "react"

import { HBox, Spacer, VBox, BigButton } from "@molnx-sites/common/components"
import { PageSection } from "src/components"

import styles from "./Footer.module.scss"

export function Footer() {
    return (
        <PageSection dark className={styles.Footer}>
            <div className={styles.Sections}>
                <Services/>
                <Company/>
                <Help/>
            </div>

            <HBox className={styles.ButtonRow}>
                <BigButton href="https://portal.molnx.com">Go to portal</BigButton>
            </HBox>

            <HBox wrap className={styles.FinePrint}>
                <Link to={"/terms-of-service"}>Terms of Service</Link>
                <Spacer width="small" inline/>
                &middot;
                <Spacer width="small" inline/>
                <Link to={"/privacy-policy"}>Privacy Policy</Link>
                <Spacer width="small" inline/>
                &middot;
                <Spacer width="small" inline/>
                &copy; 2020, MolnX &mdash; All Rights Reserved
            </HBox>
        </PageSection>
    )
}

function Services() {
    return (
        <Section header="Services">
            <ul>
                <li>
                    <Link to="/dispatch">Dispatch</Link>
                    <br/>
                    <span className={styles.ServiceLinks}>
                        <Link to="/dispatch#pricing">Pricing</Link>
                        <Spacer width="small" inline/>
                        &middot;
                        <Spacer width="small" inline/>
                        <Link to="/dispatch#documentation">Documentation</Link>
                        <Spacer width="small" inline/>
                    </span>
                </li>
            </ul>
        </Section>
    )
}

function Company() {
    return (
        <Section header="Company">
            <ul>
                <li><Link to="/about-us">About Us</Link></li>
                <li><Link to="/news">News</Link></li>
                <li><Link to="/blog">Blog</Link></li>
            </ul>
        </Section>
    )
}

function Help() {
    return (
        <Section header="Help">
            <ul>
                <li><Link to="/contact-us">Contact Us</Link></li>
                <li><Link to="/support">Support</Link></li>
            </ul>
        </Section>
    )
}

function Section(props: {
    header: string
    children?: any
}) {
    const {
        header,
        children
    } = props

    return (
        <VBox className={styles.Section}>
            <strong>{header}</strong>
            <hr/>
            {children}
        </VBox>
    )
}