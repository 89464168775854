import React from "react"
import { Link } from "gatsby"

import { HBox, Spacer, Logo, BigButton } from "@molnx-sites/common/components"

import styles from "./Header.module.scss"


export default function NormalNav(props: {
}) {
    return (
        <HBox className={styles.Content}>
            <Link to="/">
                <Logo tiny/>
            </Link>
            <Spacer/>

            <ul className={styles.Links}>
                <li><Link to="/services">Services</Link></li>
                <li><a href="https://molnx.com/docs/">Documentation</a></li>
                <li><Link to="/support">Support</Link></li>
            </ul>

            <BigButton href="https://portal.molnx.com" className={styles.PortalButton}>Go to portal</BigButton>
        </HBox>
    )
}
