import * as React from "react"

import classNames from "@molnx-sites/common/utils/classNames"
import { PageColumn } from "src/components"

import styles from "./PageSection.module.scss"

export function PageSection(props: {
    dark?: any
    className?: string
    children?: any
}) {
    const className = classNames(
        styles.PageSection,
        props.className,
        props.dark ? "dark" : undefined
    )

    return (
        <div className={className}>
            <PageColumn>
                {props.children}
            </PageColumn>
        </div>
    )
}