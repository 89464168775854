import React from "react"

import { PageColumn } from "src/components"
import { OnDevice } from "@molnx-sites/common/components"

import NormalNav from "./NormalNav"
import MobileNav from "./MobileNav"

import styles from "./Header.module.scss"

export function Header() {
    return (
        <header className={styles.Header}>
            <PageColumn>
                <OnDevice desktop tablet render={() => <NormalNav />} />
                <OnDevice mobile render={() => <MobileNav />} />
            </PageColumn>
        </header>
    )
}